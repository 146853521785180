// Разные скрипты

$(function(){
  $('.button__portfolio').click(function(){
    $('.portfolio').toggleClass('portfolio__active');
  });
});
// Разные скрипты


// ------------Галерея--------------

$(document).ready(function() {
  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1] // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
      }
    }
  });



    $('.image-popup-no-margins').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    closeBtnInside: false,
    fixedContentPos: true,
    mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
    image: {
      verticalFit: true
    },
    zoom: {
      enabled: true,
      duration: 300 // don't foget to change the duration also in CSS
    }
  });

});

// ------------Галерея--------------


// ----------------------Модалки-----------------------



 $(document).ready(function() {
      $('.popup-content').magnificPopup({
                type: 'inline'
                    });
    });
// ----------------------Модалки-----------------------



// --------------------Слайдеры------------------------
 $('.single-item').slick({
      infinite: true,
       nextArrow:'<i class="fa fa-arrow-circle-right certificat__fa" aria-hidden="true"></i>',
      prevArrow:'<i class="fa fa-arrow-circle-left certificat__fa" aria-hidden="true"></i>',
      slidesToShow: 1,  
      adaptiveHeight: true,
      fade: true,    
      slidesToScroll: 1
    });


 $('.slider__two').slick({
      infinite: true,
      nextArrow:'<i class="fa fa-arrow-circle-right certificat__fa" aria-hidden="true"></i>',
      prevArrow:'<i class="fa fa-arrow-circle-left certificat__fa" aria-hidden="true"></i>',
      slidesToShow: 6,  
      slidesToScroll: 1,
      responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
     {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
     {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
     {
      breakpoint: 574,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
    });

// $('.left').click(function(){
//   $('.slider').slick('slickPrev');
// })

// $('.right').click(function(){
//   $('.slider').slick('slickNext');
// })

// --------------------Слайдеры------------------------









// -------------------скроолинг---------------------------
$(document).ready(function(){
    $('.link').click( function(){ // ловим клик по ссылке с классом go_to
  var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
        if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
      $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 800); // анимируем скроолинг к элементу scroll_el
    }
      return false; // выключаем стандартное действие
    });
  });
// -------------------скроолинг-----------------------------



// ------------Валидация-и-отправка-формы-------------------//

$(document).ready(function() {
    $('form').submit(function(e) { 
       e.preventDefault();
    var $that = $(this),
    formData = new FormData($that.get(0)); // создаем новый экземпляр объекта и передаем ему нашу форму (*)
      $('#loader').fadeIn();
        $.ajax({
            type: "POST",
            url: "php/mail.php",
            contentType: false, // важно - убираем форматирование данных по умолчанию
      processData: false, // важно - убираем преобразование строк по умолчанию
      data: formData,
        }).always(function (response) {
              setTimeout(function (){
                 $('#loader').fadeOut();
              },800);
              setTimeout(function (){
                  $('#overlay__thank_you').fadeIn();
                  $form.trigger('reset');
              },800);
              $('#overlay__thank_you').on('click', function(e) {
                  $('#overlay__thank_you').fadeOut();
              });
            });
        return false;
    });
});


$(function($){
  $('[name="phone"]').mask("+7(999) 999-9999");
});


// ------------Коец-Скрипта-Валидация-и-отправка-формы-------------------//

